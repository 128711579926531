/**
 * @generated SignedSource<<2921ab112be99992b981e6f2d373af7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type ShortcutsCardQuery$variables = {
  brandID: string;
};
export type ShortcutsCardQuery$data = {
  readonly navigationItemsImported: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly index: number;
        readonly label: string;
        readonly localizations: ReadonlyArray<{
          readonly languageCode: KinnLanguageCode;
          readonly text: string;
          readonly url: string | null;
        }>;
        readonly url: string;
      }>;
      readonly index: number;
      readonly label: string;
      readonly localizations: ReadonlyArray<{
        readonly languageCode: KinnLanguageCode;
        readonly text: string;
        readonly url: string | null;
      }>;
      readonly url: string;
    }>;
    readonly index: number;
    readonly label: string;
    readonly localizations: ReadonlyArray<{
      readonly languageCode: KinnLanguageCode;
      readonly text: string;
      readonly url: string | null;
    }>;
    readonly url: string;
  }>;
};
export type ShortcutsCardQuery = {
  response: ShortcutsCardQuery$data;
  variables: ShortcutsCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LocalizedUrlType",
  "kind": "LinkedField",
  "name": "localizations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languageCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brandId",
        "variableName": "brandID"
      }
    ],
    "concreteType": "NavigationItemType",
    "kind": "LinkedField",
    "name": "navigationItemsImported",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NavigationItemType",
        "kind": "LinkedField",
        "name": "children",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NavigationItemType",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShortcutsCardQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShortcutsCardQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "d9e8d87fe179fe041afd429857b9685d",
    "id": null,
    "metadata": {},
    "name": "ShortcutsCardQuery",
    "operationKind": "query",
    "text": "query ShortcutsCardQuery(\n  $brandID: ID!\n) {\n  navigationItemsImported(brandId: $brandID) {\n    index\n    label\n    url\n    localizations {\n      languageCode\n      text\n      url\n    }\n    children {\n      index\n      label\n      url\n      localizations {\n        languageCode\n        text\n        url\n      }\n      children {\n        index\n        label\n        url\n        localizations {\n          languageCode\n          text\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe3f271a2f58da3aa515e6222c31e9e3";

export default node;
