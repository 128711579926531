import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo, useState } from "react";
import { Search } from "react-feather";
import { useLazyLoadQuery } from "react-relay";
import { NavigationPreviewQuery } from "./__generated__/NavigationPreviewQuery.graphql";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";
import { getBrandIcon } from "../../utils/brandIcons";
import { convertFromGraphQL } from "../../views/dashboard/build_section/navigation/MenuCard";
import { convertFromGraphQL as convertShortcuts } from "../../views/dashboard/build_section/navigation/ShortcutsCard";
import MobilePreviewBottomSheet from "./MobilePreviewBottomSheet";
import MobilePreviewSidepane from "./MobilePreviewSidepane";


const query = graphql`
  query NavigationPreviewQuery($id: ID!) {
    brand(id: $id) {
      id
      appConfig {
        id
        buildTheme {
          buttonCartIcon
          logoUrl
        }

        buildNavigation {
          id
          isGorgiasEnabled
          isTabBarTitleEnabled
        }

        navigationItems {
          index
          label
          localizations {
            languageCode
            text
            url
          }
          url
          children {
            index
            label
            url
            localizations {
              languageCode
              text
              url
            }
            children {
              index
              label
              url
              localizations {
                languageCode
                text
                url
              }
            }
          }
        }

        nestedMenuItems {
          title
          localizations {
            languageCode
            text
            url
          }
          destinationUrl
          index
          children {
            title
            destinationUrl
            index
            localizations {
              languageCode
              text
              url
            }
            children {
              title
              destinationUrl
              index
              localizations {
                languageCode
                text
                url
              }
            }
          }
        }

        tabs {
          id
          type
          customName
          localizations {
            languageCode
            text
            url
          }
          customUrl
          icon
        }
      }
    }
  }
`;

const NavigationPreview = ({ brandID }: { brandID: string }) => {
  const theme = useTheme();
  const { navigation, theme: themePreview } = useMobilePreviewState();

  const data = useLazyLoadQuery<NavigationPreviewQuery>(query, {
    id: brandID,
  });
  const brand = data.brand;
  const appConfig = brand.appConfig;
  const buildTheme = appConfig.buildTheme;
  const navigationItems = appConfig.navigationItems;
  const isGorgiasEnabled = appConfig.buildNavigation.isGorgiasEnabled;
  const isTabBarTitleEnabled = appConfig.buildNavigation.isTabBarTitleEnabled;
  const items = useMemo(
    () => appConfig.nestedMenuItems ?? [],
    [appConfig.nestedMenuItems]
  );

  // Mobile States
  const defaultNestedMenuItems =
    navigation.nestedMenuItems ?? convertFromGraphQL(items);
  const defaultShortcutsPills =
    navigation.shortcutsPills ?? convertShortcuts(navigationItems);
  const defaultTabs =
    navigation.tabs ??
    brand.appConfig.tabs.map((tab) => {
      const { id, ...rest } = tab;
      return rest;
    });
  const defaultIsTitleEnabled =
    navigation.isTitleEnabled ?? isTabBarTitleEnabled;
  const defaultIsGorgiasEnabled =
    navigation.isGorgiasEnabled ?? isGorgiasEnabled;

  // Theme States
  const defaultButtonCartIcon =
    themePreview.buttonCartIcon ?? buildTheme.buttonCartIcon ?? "SHOPPING_BAG";
  const logoUrl = themePreview.logoUrl ?? buildTheme.logoUrl;

  const CartIcon = getBrandIcon(defaultButtonCartIcon);

  // Needed to skip animation on first render
  const [isSidepaneFirstRender, setSidepaneFirstRender] = useState(true);
  const [isBottomSheetFirstRender, setIsBottomSheetFirstRender] =
    useState(true);

  const [value, setValue] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [bottomSheetIndex, setBottomSheetIndex] = useState(-1);
  const shortcutItems = defaultShortcutsPills.map((item, index) => {
    return (
      <Button
        key={index}
        sx={{
          backgroundColor: theme.palette.primaryPillBg.main,
          "&:hover": {
            backgroundColor: theme.palette.primaryPillBg.main + "CC",
          },
          p: "8px 12px",
          flexShrink: 0,
          borderColor: theme.palette.secondaryPillOutline.main,
          borderWidth:
            theme.palette.secondaryPillOutline.main === "transparent" ? 0 : 2,
          borderStyle: "solid",
        }}
        onClick={() => {
          if (item?.children?.length > 0) {
            setBottomSheetIndex(index);
            setBottomSheetOpen(true);
            setIsBottomSheetFirstRender(false);
          }
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primaryPillFg.main,
            fontSize: "10px",
          }}
          variant="body0"
        >
          {item.label || "--"}
        </Typography>
      </Button>
    );
  });

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "3.75px solid #DBDCDD",
        justifyContent: "center",
        overflow: "hidden",
        alignContent: "center",
        height: "650px",
        alignItems: "center",
        backgroundColor: "white",
        mt: 2,
        position: "relative",
      }}
      id="drawer-container"
    >
      <MobilePreviewSidepane
        items={defaultNestedMenuItems}
        isFirstRender={isSidepaneFirstRender}
        isOpen={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      />
      <MobilePreviewBottomSheet
        activeShortcut={defaultShortcutsPills[bottomSheetIndex]}
        isOpen={isBottomSheetOpen}
        isFirstRender={isBottomSheetFirstRender}
        onClose={() => {
          setBottomSheetOpen(false);
        }}
      />
      <Stack
        sx={{
          backgroundColor: theme.palette.navigationBackground.main,
          color: theme.palette.navigationForeground.main,
          height: "56px",
          padding: "12px",
          width: "100%",
          borderBottom: "1px solid #F1F1F1",
        }}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <IconButton
            onClick={() => {
              setMenuOpen(true);
              setSidepaneFirstRender(false);
            }}
            sx={{ color: theme.palette.navigationForeground.main }}
          >
            <MenuIcon />
          </IconButton>
          <img
            style={{ maxHeight: "36px", maxWidth: "130px" }}
            alt=""
            src={logoUrl ?? ""}
            loading="lazy"
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Search />
          <CartIcon />
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        width={"100%"}
        spacing={1}
        alignItems={"center"}
        overflow={"scroll"}
        marginTop={"-1px"}
        p={1}
        sx={{ backgroundColor: theme.palette.pillContainerBackground.main }}
      >
        {shortcutItems}
      </Stack>

      <Stack spacing={1}>
        <Stack p={1} spacing={2}>
          <Skeleton
            sx={{
              backgroundColor: "#FAF9F9",
              width: "100%",
            }}
            variant="rounded"
            height={56}
            animation={false}
          />
          {[0, 1, 2].map((item, index) => {
            return (
              <Skeleton
                key={index}
                sx={{
                  backgroundColor: "#FAF9F9",
                  width: "100%",
                }}
                variant="rounded"
                height={150}
                animation={false}
              />
            );
          })}
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
        alignItems={"end"}
      >
        {defaultIsGorgiasEnabled && (
          <IconButton
            sx={{
              m: 2,
              width: "52px",
              height: "52px",
              zIndex: 50,
              backgroundColor: theme.palette.primaryButtonBg.main,
              "&:hover": {
                backgroundColor: theme.palette.primaryButtonBg.main + "CC",
              },
              color: theme.palette.primaryButtonFg.main,
              boxShadow: "2px 4px 4px 0px rgba(47, 55, 146, 0.15)",
            }}
          >
            <ChatBubbleIcon />
          </IconButton>
        )}
        <Tabs
          centered
          variant="fullWidth"
          sx={{
            width: "100%",
            backgroundColor: theme.palette.navigationBackground.main,
            color: theme.palette.navigationForeground.main,
          }}
          textColor="inherit"
          TabIndicatorProps={{
            sx: {
              top: 0,
              backgroundColor: theme.palette.navigationForeground.main,
            },
          }}
          value={value}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
          }}
        >
          {defaultTabs.map((tab, index) => {
            const Icon = getBrandIcon(tab.icon);
            const icon = <Icon size={24} color="currentColor" />;

            return (
              <Tab
                sx={{
                  textTransform: "none",
                  padding: 0,

                  "&.MuiButtonBase-root.MuiTab-root": {
                    flex: "1 1 0",
                    minWidth: "0",
                  },
                }}
                key={index}
                label={
                  defaultIsTitleEnabled ? (
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      {tab.customName}
                    </Typography>
                  ) : undefined
                }
                icon={icon}
              />
            );
          })}
        </Tabs>
      </Stack>
    </Box>
  );
};

export default NavigationPreview;