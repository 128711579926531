import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Button, CircularProgress, Stack, TextField, Typography, debounce } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { Control, FieldArrayPath, SetValueConfig, useFieldArray, useForm } from "react-hook-form";
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from "react-relay";
import * as yup from "yup";
import { MenuCardMutation } from "./__generated__/MenuCardMutation.graphql";
import { MenuCardQuery } from "./__generated__/MenuCardQuery.graphql";
import { MenuCard_brand$data, MenuCard_brand$key } from "./__generated__/MenuCard_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LanguageSwitch, { useLanguageSwitch } from "../../../../components/LanguageSwitch";
import LeftRight from "../../../../components/LeftRight";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import { ReducerAction, useMobilePreviewDispatch } from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import { DeepWriteable, getValueAtPath } from "../../../../utils/typeUtils";
import { validateUrl } from "../../../../utils/validators";


const importQuery = graphql`
  query MenuCardQuery($brandID: ID!) {
    menuItemsImported(brandId: $brandID) {
      title
      destinationUrl
      index
      localizations {
        languageCode
        text
        url
      }
      children {
        title
        destinationUrl
        index
        localizations {
          languageCode
          text
          url
        }
        children {
          title
          destinationUrl
          index
          localizations {
            languageCode
            text
            url
          }
        }
      }
    }
  }
`;

const brandFragment = graphql`
  fragment MenuCard_brand on BrandType {
    id
    ...useBrandLanguages_brand
    appConfig {
      id
      nestedMenuItems {
        title
        localizations {
          languageCode
          text
          url
        }
        destinationUrl
        index
        children {
          title
          destinationUrl
          index
          localizations {
            languageCode
            text
            url
          }
          children {
            title
            destinationUrl
            index
            localizations {
              languageCode
              text
              url
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation MenuCardMutation($input: UpdateMenuItemsInput!) {
    updateMenuItems(input: $input) {
      ... on BrandType {
        ...MenuCard_brand
      }
    }
  }
`;

export type MenuItem = {
  destinationUrl?: string;
  title: string;
  // make url optional instead of nullable
  localizations: DeepWriteable<
    Omit<
      MenuCard_brand$data["appConfig"]["nestedMenuItems"][number]["localizations"][number],
      "url" | "text"
    > & {
      text?: string;
      url?: string;
    }
  >[];
  items?: Array<MenuItem>;
};

const newMenuItem = ({
  supportedLanguages,
  showUrl,
}: {
  supportedLanguages?: KinnLanguageCode[];
  showUrl: boolean;
}): MenuItem => {
  return {
    title: "",
    // "" will validate as required, undefined as optional (set through yup schema)
    destinationUrl: showUrl ? "" : undefined,
    localizations:
      supportedLanguages?.map((lang) => ({
        languageCode: lang,
        text: "",
        url: showUrl ? "" : undefined,
      })) ?? [],
  };
};

type LocalizationChangeProps = {
  field: MenuItem;
  sectionIndex: number;
  langIndex: number;
  value: string;
  fieldName: "text" | "url";
  basePath?: FieldArrayPath<FormValues>;
};

const localizationsSchema = yup
  .array()
  .required()
  .of(
    yup.object({
      text: yup.string().required("Required").trim(),
      url: yup
        .string()
        .optional()
        .max(2083, "URL must be less than 2083 characters")
        .trim()
        .test("is-url-valid", "URL is not valid", (value) => {
          if (!value) {
            return true;
          }
          return validateUrl(value);
        }),
      languageCode: yup.mixed<KinnLanguageCode>().required("Required"),
    })
  );

const menuSchema: yup.ObjectSchema<MenuItem> = yup.object({
  title: yup.string().required("Required").trim(),
  destinationUrl: yup
    .string()
    .optional()
    .max(2083, "URL must be less than 2083 characters")
    .trim()
    .test("is-url-valid", "URL is not valid", (value) => {
      if (value == null) {
        return true;
      }
      return validateUrl(value);
    }),
  localizations: localizationsSchema,
  items: yup
    .array()
    .optional()
    .of(
      yup.object({
        title: yup.string().required("Required").trim(),
        destinationUrl: yup
          .string()
          .optional()
          .max(2083, "URL must be less than 2083 characters")
          .trim()
          .test("is-url-valid", "URL is not valid", (value) => {
            if (value == null) {
              return true;
            }
            return validateUrl(value);
          }),
        localizations: localizationsSchema,
        items: yup
          .array()
          .optional()
          .of(
            yup.object({
              title: yup.string().required("Required").trim(),
              destinationUrl: yup
                .string()
                .optional()
                .max(2083, "URL must be less than 2083 characters")
                .trim()
                .test("is-url-valid", "URL is not valid", (value) => {
                  if (value == null) {
                    return true;
                  }
                  return validateUrl(value);
                }),
              localizations: localizationsSchema,
            })
          ),
      })
    ),
});
const schema = yup
  .object({
    items: yup.array().of(menuSchema).required(),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

type Props = {
  brand: MenuCard_brand$key;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingTop: "12px",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const MenuItemRow = ({
  droppableIndex,
  sectionIndex,
  control,
  register,
  errors,
  isMenuItem,
  supportedLanguages,
  selectedLanguage,
  onLocalizationChange,
  onDragEnd,
  baseArrayName,
}: {
  droppableIndex: string;
  sectionIndex: number;
  control: Control<FormValues, any>;
  register: any;
  errors: any;
  isMenuItem: boolean;
  supportedLanguages?: KinnLanguageCode[];
  selectedLanguage?: KinnLanguageCode;
  onLocalizationChange: (props: LocalizationChangeProps) => void;
  onDragEnd: (
    sectionIndex: string,
    reorderCallback: (from: number, to: number) => void
  ) => void;
  baseArrayName: FieldArrayPath<FormValues>;
}) => {
  const depth = droppableIndex.split(".").length;

  const arrayName = `${baseArrayName}.${sectionIndex}.items` as const;
  const {
    fields,
    remove: removeItem,
    append: appendItem,
    move,
  } = useFieldArray({
    control,
    name: arrayName,
  });

  useEffect(() => {
    if (droppableIndex && !isMenuItem) {
      onDragEnd(droppableIndex, (from: number, to: number) => {
        move(from, to);
      });
    }
  }, [move, onDragEnd, droppableIndex, isMenuItem]);

  if (depth > 2) {
    return null;
  }

  return (
    <Stack ml={5} spacing={1}>
      <Droppable
        droppableId={"menu-item-droppable-" + droppableIndex}
        type={"CHILD-" + droppableIndex}
      >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((field, index) => {
              // @ts-ignore
              const insideIsMenuItem = field.destinationUrl != null;

              const selectedLanguageIndex = field.localizations.findIndex(
                (loc) => loc.languageCode === selectedLanguage
              );
              const textPathSuffix = supportedLanguages
                ? (`localizations.${selectedLanguageIndex}.text` as const)
                : ("title" as const);
              const urlPathSuffix = supportedLanguages
                ? (`localizations.${selectedLanguageIndex}.url` as const)
                : ("destinationUrl" as const);
              const languageSuffix = selectedLanguage
                ? ` (${selectedLanguage})`
                : "";

              const registerTextProps = register(
                `${arrayName}.${index}.${textPathSuffix}` as const,
                {
                  maxLength: 255,
                }
              );
              const registerUrlProps = register(
                `${arrayName}.${index}.${urlPathSuffix}` as const
              );

              return (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="stretch"
                        sx={{
                          background: "#FDFDFE",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <DragIndicatorIcon />
                        </Box>
                        <TextField
                          key={`text-${selectedLanguageIndex}`}
                          {...registerTextProps}
                          sx={{
                            flexGrow: 1,
                          }}
                          inputProps={{
                            maxLength: 255,
                          }}
                          margin="normal"
                          id="outlined-basic"
                          label={`Menu Title${languageSuffix}`}
                          variant="outlined"
                          error={!!errors?.items?.[index]?.title}
                          helperText={errors?.items?.[index]?.title?.message}
                          onChange={
                            supportedLanguages
                              ? (event) => {
                                  onLocalizationChange({
                                    field,
                                    sectionIndex: index,
                                    langIndex: selectedLanguageIndex,
                                    value: event.target.value,
                                    fieldName: "text",
                                    basePath: arrayName,
                                  });
                                }
                              : registerTextProps.onChange
                          }
                        />
                        {insideIsMenuItem && (
                          <TextField
                            key={`url-${selectedLanguageIndex}`}
                            {...registerUrlProps}
                            sx={{
                              flexGrow: 1,
                              "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                            margin="normal"
                            id="outlined-basic"
                            label={`Destination Url${languageSuffix}`}
                            variant="outlined"
                            error={!!errors?.items?.[index]?.destinationUrl}
                            helperText={
                              errors?.items?.[index]?.destinationUrl?.message
                            }
                            onChange={
                              supportedLanguages
                                ? (event) => {
                                    onLocalizationChange({
                                      field,
                                      sectionIndex: index,
                                      langIndex: selectedLanguageIndex,
                                      value: event.target.value,
                                      fieldName: "url",
                                      basePath: arrayName,
                                    });
                                  }
                                : registerUrlProps.onChange
                            }
                          />
                        )}
                        {fields.length <= 1 ? null : (
                          <Button
                            disabled={false}
                            size="small"
                            variant="text"
                            color="error"
                            onClick={() => {
                              removeItem(index);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Stack>
                      <MenuItemRow
                        droppableIndex={`${droppableIndex}.${index}`}
                        sectionIndex={index}
                        control={control}
                        register={register}
                        errors={errors?.items?.[index]}
                        isMenuItem={insideIsMenuItem}
                        onDragEnd={onDragEnd}
                        baseArrayName={arrayName}
                        supportedLanguages={supportedLanguages}
                        selectedLanguage={selectedLanguage}
                        onLocalizationChange={onLocalizationChange}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Stack direction={"row"} alignItems={"center"} alignContent={"center"}>
        {!isMenuItem && (
          <Button
            variant="text"
            onClick={() => {
              appendItem(
                newMenuItem({
                  supportedLanguages,
                  showUrl: true,
                })
              );
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Menu Item
          </Button>
        )}
        {!(depth >= 2 || isMenuItem) && (
          <Button
            disabled={false}
            variant="text"
            onClick={() => {
              appendItem({
                ...newMenuItem({
                  supportedLanguages,
                  showUrl: false,
                }),
                items: [
                  newMenuItem({
                    supportedLanguages,
                    showUrl: true,
                  }),
                ],
              });
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Menu Section
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export type MenuItemGraphQL = {
  destinationUrl: string | null;
  index: number;
  title: string;
  localizations: MenuCard_brand$data["appConfig"]["nestedMenuItems"][number]["localizations"];
  children?: ReadonlyArray<MenuItemGraphQL>;
};

export const convertFromGraphQL = (
  menuItems: ReadonlyArray<MenuItemGraphQL>,
  supportedLanguages: KinnLanguageCode[] = []
): Array<MenuItem> => {
  return Array.from(menuItems)
    .sort((a, b) => a.index - b.index)
    .map((item) => {
      const localizations = item.localizations
        .filter((loc) => {
          return supportedLanguages.includes(loc.languageCode);
        })
        .map((localization) => ({
          ...localization,
          url: localization.url ?? undefined,
        }));
      if (item.children == null || item.children.length === 0) {
        return {
          title: item.title,
          destinationUrl: item?.destinationUrl ?? "",
          localizations,
        };
      }
      return {
        title: item.title,
        localizations,
        items: convertFromGraphQL(item.children, supportedLanguages),
      };
    });
};

const convertFromLocal = (
  menuItems: ReadonlyArray<MenuItem>
): ReadonlyArray<MenuItemGraphQL> => {
  return Array.from(menuItems).map((item, index) => {
    const items = item?.items;
    const localizations = item.localizations
      .map((localization) => ({
        ...localization,
        text: localization.text ?? "",
        url: localization.url ?? null,
      }))
      .filter((loc) => loc.text.length > 0 || loc.url != null);
    if (items == null || items.length === 0) {
      return {
        index,
        title: item.title,
        destinationUrl: item.destinationUrl ?? "",
        localizations,
        children: [],
      };
    }
    return {
      index,
      title: item.title,
      destinationUrl: null,
      localizations,
      children: convertFromLocal(items),
    };
  });
};

const localizedMenuItems = (
  items: MenuItem[],
  selectedLanguage?: KinnLanguageCode
): MenuItem[] => {
  if (!selectedLanguage) {
    return items;
  }
  const result = items.map((item) => {
    const localized = item.localizations.find(
      (loc) => loc.languageCode === selectedLanguage
    );
    return {
      ...item,
      title: localized?.text ?? item.title,
      destinationUrl: localized?.url ?? item.destinationUrl,
      items: localizedMenuItems(item.items ?? [], selectedLanguage),
    };
  });
  return result;
};

const MenuCard = ({ brand: brandKey }: Props) => {
  const snackbarContext = useContext(SnackbarContext);
  const dispatch = useMobilePreviewDispatch();
  const environment = useRelayEnvironment();

  const brand = useFragment<MenuCard_brand$key>(brandFragment, brandKey);
  const appConfig = brand.appConfig;
  const items = useMemo(
    () => appConfig.nestedMenuItems ?? [],
    [appConfig.nestedMenuItems]
  );
  const [isImporting, setIsImporting] = useState(false);

  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    items.length === 0 ? SavedState.DISABLED : SavedState.SAVED
  );

  const [saveMutation, isMutationInFlight] =
    useMutation<MenuCardMutation>(mutation);

  const languageProps = useLanguageSwitch({
    brand,
  });

  const defaultValues = useMemo(
    () => ({
      items: convertFromGraphQL(
        items,
        languageProps?.supportedLanguages?.map((lang) => lang.code) ?? []
      ),
    }),
    [items, languageProps?.supportedLanguages]
  );
  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
    reset,
    watch,
    getValues,
    setValue,
    getFieldState,
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    fields,
    append: appendSection,
    remove: removeSection,
    move,
  } = useFieldArray({
    control,
    name: "items",
  });

  const refreshPreview = useMemo(
    () =>
      debounce((items: MenuItem[], selectedLanguage?: KinnLanguageCode) => {
        dispatch({
          type: ReducerAction.UPDATE_NAVIGATION,
          payload: {
            navigation: {
              nestedMenuItems: localizedMenuItems(items, selectedLanguage),
            },
          },
        });
      }, 200),
    [dispatch]
  );

  useEffect(() => {
    const subscription = watch((values) => {
      const items = JSON.parse(JSON.stringify(getValues().items));
      refreshPreview(items, languageProps?.selectedLanguage);
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, languageProps?.selectedLanguage, refreshPreview]);

  useEffect(() => {
    refreshPreview(getValues().items, languageProps?.selectedLanguage);
  }, [getValues, languageProps?.selectedLanguage, refreshPreview]);

  const childrenRef = useRef<{
    [sectionIndex: string]: (from: number, to: number) => void;
  }>({});
  const setReorder = useCallback(
    (
      sectionIndex: string,
      reorderCallback: (from: number, to: number) => void
    ) => {
      childrenRef.current[sectionIndex] = reorderCallback;
    },
    [childrenRef]
  );

  const onSubmit = (data: FormValues) => {
    setSaveButtonState(SavedState.SAVING);
    saveMutation({
      variables: {
        input: {
          appConfigId: brand.appConfig.id,
          menuItemsInput: convertFromLocal(data.items),
        },
      },
      onCompleted: (d, errors) => {
        if (errors) {
          snackbarContext?.openSnackbar("Update Failed", "error");
        } else {
          snackbarContext?.openSnackbar("Updated", "success");
          setSaveButtonState(SavedState.SAVED);
          reset(data);
        }
      },
      onError: () => {
        snackbarContext?.openSnackbar("Update Failed", "error");
        setSaveButtonState(SavedState.ENABLED);
      },
    });
  };
  const handleLocalizationChange = useCallback(
    ({
      field,
      sectionIndex,
      langIndex,
      value,
      fieldName,
      basePath = "items",
    }: LocalizationChangeProps) => {
      // needed for getValues to work correctly
      setValue(
        `${basePath}.${sectionIndex}.localizations.${langIndex}.${fieldName}` as const,
        value
      );

      const generalFieldName =
        fieldName === "text" ? "title" : ("destinationUrl" as const);
      const generalValuePath =
        `${basePath}.${sectionIndex}.${generalFieldName}` as const;
      const dontTouchOptions: SetValueConfig = {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      };
      setSaveButtonState(SavedState.ENABLED);
      setValue(generalValuePath, value);

      const initialLocalizations = getValueAtPath(
        defaultValues,
        `${basePath}.${sectionIndex}.localizations`
      );
      field.localizations.forEach((loc, locIndex) => {
        if (locIndex === langIndex) {
          return;
        }
        const locPath =
          `${basePath}.${sectionIndex}.localizations.${locIndex}.${fieldName}` as const;
        const locState = getFieldState(locPath);
        const initialValue = initialLocalizations?.[locIndex]?.[fieldName];
        if (!loc.text || (!locState.isTouched && !initialValue)) {
          setValue(locPath, value, dontTouchOptions);
        }
      });
    },
    [getFieldState, setValue, defaultValues]
  );

  const handleLocalizationChangeDebounced = debounce(
    handleLocalizationChange,
    500
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <DragDropContext
        onDragEnd={(result) => {
          const { destination, source } = result;

          if (!destination) {
            return;
          }

          if (
            destination.droppableId !== source.droppableId &&
            destination.index === source.index
          ) {
            return;
          }

          /*
            NOTE: We use flushSync to ensure array state is finished updating after move is called after a drag and drop.
            This is possibly due to a few reasons: 
            - React 18 batches updates
            - Nested menu is heavy
            - Validation on top of that is heavy

            flushsync: https://react.dev/reference/react-dom/flushSync#flushing-updates-for-third-party-integrations
            Issue: https://github.com/react-hook-form/react-hook-form/issues/11050
            Another person's issue: https://github.com/atlassian/react-beautiful-dnd/issues/2475

            - TODO: figure out why saving rerenders 3 times instead of once
            - TODO: look at source code to see why move and dnd don't work together
          */
          if (result.type === "PARENT") {
            flushSync(() => move(source.index, destination.index));
          } else {
            let droppableInfo = destination.droppableId.split("-").pop() ?? "";
            const reorderCallback = childrenRef.current[droppableInfo];
            reorderCallback &&
              flushSync(() => reorderCallback(source.index, destination.index));
          }
        }}
      >
        <CardSection
          actions={
            <SaveButton
              savedState={
                isMutationInFlight || saveButtonState === SavedState.SAVING
                  ? SavedState.SAVING
                  : isDirty
                  ? SavedState.ENABLED
                  : saveButtonState
              }
              useSubmit={true}
            />
          }
          title="Menu"
          titleActions={
            languageProps && <LanguageSwitch {...languageProps} size="small" />
          }
          subtitle={
            "Menu items live in the menu icon in your app’s navigation bar."
          }
          content={
            <Stack spacing={1} width="100%">
              <Stack spacing={1}>
                <LeftRight
                  expandLeft
                  left={
                    <>
                      <Typography variant="subtitle1">
                        Customize your menu items
                      </Typography>
                      <Typography variant="body2">
                        You can import menu items from your Shopify website
                        first, or manually create and edit items yourself.
                      </Typography>
                    </>
                  }
                  right={
                    <Button
                      sx={{
                        boxShadow:
                          "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
                        borderRadius: "8px",
                        flexShrink: 0,
                      }}
                      startIcon={
                        isImporting ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : null
                      }
                      disabled={isImporting}
                      size="medium"
                      variant="contained"
                      color="secondaryLight"
                      onClick={() => {
                        fetchQuery<MenuCardQuery>(environment, importQuery, {
                          brandID: brand.id,
                        }).subscribe({
                          start: () => {
                            setIsImporting(true);
                          },
                          complete: () => {
                            setIsImporting(false);
                          },
                          error: (_: Error) => {
                            setIsImporting(false);
                          },
                          next: (data) => {
                            reset({
                              items: convertFromGraphQL(
                                data.menuItemsImported,
                                languageProps?.supportedLanguages?.map(
                                  (lang) => lang.code
                                ) ?? []
                              ),
                            });
                            setSaveButtonState(SavedState.ENABLED);
                          },
                        });
                      }}
                    >
                      {isImporting ? "Importing" : "Import all"}
                    </Button>
                  }
                />
              </Stack>

              <Droppable droppableId={"menu-section-droppable"} type="PARENT">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((field, sectionIndex) => {
                      const isSection = field.destinationUrl == null;
                      const selectedLanguageIndex =
                        field.localizations.findIndex(
                          (loc) =>
                            loc.languageCode === languageProps?.selectedLanguage
                        );
                      const textPathSuffix = languageProps?.supportedLanguages
                        ? (`localizations.${selectedLanguageIndex}.text` as const)
                        : ("title" as const);
                      const urlPathSuffix = languageProps?.supportedLanguages
                        ? (`localizations.${selectedLanguageIndex}.url` as const)
                        : ("destinationUrl" as const);
                      const languageSuffix = languageProps?.selectedLanguage
                        ? ` (${languageProps.selectedLanguage})`
                        : "";

                      const registerTextProps = register(
                        `items.${sectionIndex}.${textPathSuffix}` as const,
                        {
                          maxLength: 255,
                        }
                      );
                      const registerUrlProps = register(
                        `items.${sectionIndex}.${urlPathSuffix}` as const
                      );

                      const menuSectionRow = (
                        <Stack width={"100%"} direction="row" spacing={1}>
                          <TextField
                            // key is required so that the component remouts, and sets correct value on language change
                            key={`text-${selectedLanguageIndex}`}
                            {...registerTextProps}
                            error={!!errors?.items?.[sectionIndex]?.title}
                            helperText={
                              errors?.items?.[sectionIndex]?.title?.message
                            }
                            sx={{
                              flexGrow: 1,
                            }}
                            id="outlined-basic"
                            label={`Menu Section Title${languageSuffix}`}
                            variant="outlined"
                            onChange={
                              languageProps?.supportedLanguages
                                ? (event) => {
                                    handleLocalizationChangeDebounced({
                                      field,
                                      sectionIndex,
                                      langIndex: selectedLanguageIndex,
                                      value: event.target.value,
                                      fieldName: "text",
                                    });
                                  }
                                : registerTextProps.onChange
                            }
                          />
                          {!isSection && (
                            <TextField
                              key={`url-${selectedLanguageIndex}`}
                              {...registerUrlProps}
                              error={
                                !!errors?.items?.[sectionIndex]?.destinationUrl
                              }
                              helperText={
                                errors?.items?.[sectionIndex]?.destinationUrl
                                  ?.message
                              }
                              sx={{
                                flexGrow: 1,
                              }}
                              id="outlined-basic"
                              label={`Destination Url${languageSuffix}`}
                              variant="outlined"
                              onChange={
                                languageProps?.supportedLanguages
                                  ? (event) => {
                                      handleLocalizationChangeDebounced({
                                        field,
                                        sectionIndex,
                                        langIndex: selectedLanguageIndex,
                                        value: event.target.value,
                                        fieldName: "url",
                                      });
                                    }
                                  : registerUrlProps.onChange
                              }
                            />
                          )}
                          <Button
                            disabled={false}
                            size="small"
                            variant="text"
                            color="error"
                            onClick={() => {
                              removeSection(sectionIndex);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      );

                      return (
                        <Draggable
                          key={field.id}
                          draggableId={field.id}
                          index={sectionIndex}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="stretch"
                              >
                                <DragIndicatorIcon
                                  sx={{
                                    marginTop: 2,
                                  }}
                                />
                                <Stack
                                  sx={{
                                    borderRadius: "8px",
                                    border: "1px solid #EEEFF8",
                                    background: "#FDFDFE",
                                    p: 2,
                                    width: "100%",
                                  }}
                                >
                                  {menuSectionRow}
                                  <MenuItemRow
                                    droppableIndex={"" + sectionIndex}
                                    sectionIndex={sectionIndex}
                                    control={control}
                                    register={register}
                                    errors={errors?.items?.[sectionIndex]}
                                    isMenuItem={!isSection}
                                    onDragEnd={setReorder}
                                    baseArrayName={"items"}
                                    supportedLanguages={
                                      languageProps?.supportedLanguageCodes
                                    }
                                    selectedLanguage={
                                      languageProps?.selectedLanguage
                                    }
                                    onLocalizationChange={
                                      handleLocalizationChangeDebounced
                                    }
                                  />
                                </Stack>
                              </Stack>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Stack direction={"row"} alignContent={"center"} spacing={1}>
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondaryLight"
                  onClick={() => {
                    appendSection(
                      newMenuItem({
                        supportedLanguages:
                          languageProps?.supportedLanguageCodes,
                        showUrl: true,
                      })
                    );
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add Menu Item
                </Button>
                <Button
                  disabled={false}
                  variant="contained"
                  color="secondaryLight"
                  onClick={() => {
                    appendSection({
                      ...newMenuItem({
                        supportedLanguages:
                          languageProps?.supportedLanguageCodes,
                        showUrl: false,
                      }),
                      items: [
                        newMenuItem({
                          supportedLanguages:
                            languageProps?.supportedLanguageCodes,
                          showUrl: true,
                        }),
                      ],
                    });
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add Menu Section
                </Button>
              </Stack>
            </Stack>
          }
        />
      </DragDropContext>
    </form>
  );
};

export default MenuCard;