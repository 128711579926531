import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { yupResolver } from "@hookform/resolvers/yup";
import { graphql } from "babel-plugin-relay/macro";
import deepEqual from "deep-equal";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as FeatherIcons from "react-feather";
import { useForm } from "react-hook-form";
import { useFragment, useMutation } from "react-relay";
import * as yup from "yup";
import { useBrandLanguages_brand$key } from "../../../../hooks/__generated__/useBrandLanguages_brand.graphql";
import { TabsCard2UpdateTabConfigMutation } from "./__generated__/TabsCard2UpdateTabConfigMutation.graphql";
import { IconType, TabType, TabsCard2_brand$data, TabsCard2_brand$key } from "./__generated__/TabsCard2_brand.graphql";
import CardSection from "../../../../components/CardSection";
import LanguageSwitch, { useLanguageSwitch } from "../../../../components/LanguageSwitch";
import LeftRight from "../../../../components/LeftRight";
import RighthandDrawer from "../../../../components/RighthandDrawer";
import SaveButton, { SavedState } from "../../../../components/SaveButton";
import { ReducerAction, useMobilePreviewDispatch } from "../../../../contexts/MobilePreviewContext";
import SnackbarContext from "../../../../contexts/SnackbarContext";
import { IconName, convertToIconType, customBrandIconTypes, getBrandIcon } from "../../../../utils/brandIcons";
import { KinnLanguageCode } from "../../../../utils/languageMap";
import { validateUrl } from "../../../../utils/validators";


const TABS_WITHOUT_URLS: TabType[] = [
  "FOR_YOU",
  "NOTIFICATIONS",
  "SHOP",
  "FORUM",
];

const schema = yup
  .object({
    // NOTE: should not rely on yup validation for title, as it may store values in different languages
    title: yup
      .string()
      .required("Title is required")
      .max(15, "Title can be 15 characters at most"),
    drawerUrl: yup
      .string()
      .nullable()
      .trim()
      .test({
        name: "is-url-valid",
        message: "URL is not valid",
        test: (value) => {
          if (value == null) {
            return true;
          }
          if (value === "") {
            return false;
          }
          return validateUrl(value);
        },
      }),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

const mutation = graphql`
  mutation TabsCard2UpdateTabConfigMutation($input: UpdateTabConfigInput!) {
    updateTabConfig(input: $input) {
      ... on BrandType {
        ...TabsCard2_brand
      }
    }
  }
`;

const brandFragment = graphql`
  fragment TabsCard2_brand on BrandType {
    ...useBrandLanguages_brand
    id
    domain
    appConfig {
      id
      buildNavigation {
        id
        isTabBarTitleEnabled
        isTopTitleEnabled
        eligibleTabTypes
      }
      tabs {
        id
        type
        customName
        localizations {
          languageCode
          text
          url
        }
        customUrl
        icon
      }
    }
  }
`;

type Props = {
  brand: TabsCard2_brand$key;
};

const getLabelMapping = (tab: TabType) => {
  switch (tab) {
    case "FORUM":
      return "Forum";
    case "FOR_YOU":
      return "For You";
    case "SHOP":
      return "Shop Tab";
    case "NOTIFICATIONS":
      return "Notifications";
    case "ACCOUNT":
      return "Account";
    case "WEB":
      return "Custom";
  }
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  marginTop: "8px",
  // styles we need to apply on draggables
  ...draggableStyle,
});

type DrawerTabProps = {
  index: number;
  tab: Partial<
    Pick<
      TabsCard2_brand$data["appConfig"]["tabs"][number],
      "type" | "customName" | "customUrl" | "localizations"
    >
  > &
    Pick<TabsCard2_brand$data["appConfig"]["tabs"][number], "icon">;
};

const TabsCard2Drawer = ({
  eligibleTabs,
  onUpdateTabIndex,
  isDrawerOpen,
  onDrawerClose,
  tab: { index: drawerIndex, tab } = {
    index: -1,
    tab: {
      icon: "HOME",
    },
  },
  brandDomain,
  brandId,
  brand,
  selectedLanguage,
}: {
  eligibleTabs: TabType[];
  onUpdateTabIndex: (
    index: number,
    tab: Pick<
      TabsCard2_brand$data["appConfig"]["tabs"][number],
      "type" | "customName" | "customUrl" | "localizations" | "icon"
    >
  ) => void;
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  tab?: DrawerTabProps;
  brandDomain: string;
  brandId: string;
  brand: useBrandLanguages_brand$key;
  selectedLanguage?: KinnLanguageCode;
}) => {
  const [icon, setIcon] = useState<IconType | null>(null);
  const [selectInputValue, setSelectInputValue] = useState<TabType | "">("");

  const languageProps = useLanguageSwitch({
    brand,
    initialLanguage: selectedLanguage,
  });
  const [localizations, setLocalizations] = useState<
    DrawerTabProps["tab"]["localizations"]
  >(tab.localizations);

  useEffect(() => {
    setLocalizations(tab.localizations);
  }, [tab.localizations]);

  const onSelectInputChange = (event: SelectChangeEvent) => {
    const tabType = event.target.value as TabType;
    setSelectInputValue(tabType);
    reset({
      title: getLabelMapping(tabType),
      drawerUrl:
        tabType === "ACCOUNT"
          ? brandDomain + "/account"
          : tabType === "WEB"
          ? ""
          : null,
    });
  };

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const updateTranslations = useCallback(
    (completion?: (newLocalizations: typeof localizations) => void) => {
      if (!languageProps?.selectedLanguage) {
        completion?.(undefined);
        return;
      }
      const title = getValues("title");
      const url = getValues("drawerUrl");
      const languageCode = languageProps.selectedLanguage;
      setLocalizations((prev) => {
        const newLocalizations = prev ? [...prev] : [];
        const index = newLocalizations.findIndex(
          (name) => name.languageCode === languageCode
        );
        if (index === -1) {
          newLocalizations.push({
            languageCode,
            text: title,
            url: url ?? null,
          });
        } else {
          newLocalizations[index] = {
            ...newLocalizations[index],
            text: title,
            url: url ?? null,
          };
        }

        setTimeout(() => {
          completion?.(newLocalizations);
        }, 0);
        return newLocalizations;
      });
    },
    [languageProps?.selectedLanguage, getValues]
  );

  useEffect(() => {
    const title =
      (languageProps?.selectedLanguage &&
        localizations?.find(
          (loc) => loc.languageCode === languageProps.selectedLanguage
        )?.text) ??
      tab.customName;
    const url =
      (languageProps?.selectedLanguage &&
        localizations?.find(
          (loc) => loc.languageCode === languageProps.selectedLanguage
        )?.url) ??
      tab.customUrl ??
      null;
    reset({
      title: title ?? "",
      drawerUrl: url,
    });
    setIcon(tab.icon);
    setSelectInputValue(tab.type ?? "");
  }, [
    tab.customName,
    localizations,
    languageProps?.selectedLanguage,
    tab.customUrl,
    tab.icon,
    tab.type,
    reset,
  ]);

  const onClose = () => {
    onDrawerClose();
    reset({
      title: "",
      drawerUrl: null,
    });
  };

  const onSubmit = (data: FormValues) => {
    updateTranslations((newLocalizations) => {
      const url = data.drawerUrl ?? null;
      onUpdateTabIndex(drawerIndex, {
        customName: !!newLocalizations
          ? tab.customName ?? data.title
          : data.title,
        localizations: newLocalizations ?? [],
        customUrl: !!newLocalizations ? tab.customUrl ?? url : url,
        icon: icon ?? "HOME",
        type: selectInputValue || "WEB",
      });
      onClose();
    });
  };

  const gridIcon = useCallback(
    ({ key, iconType }: { key: string; iconType: IconType }) => {
      const Icon = getBrandIcon(iconType);
      return (
        <Grid
          key={key}
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          my={1}
        >
          <IconButton
            size={"small"}
            onClick={() => {
              setIcon(iconType);
            }}
          >
            <Icon color={icon === iconType ? "#55B685" : "#a0a0a0"} />
          </IconButton>
        </Grid>
      );
    },
    [icon]
  );

  const brandIconTypes = useMemo(
    () => customBrandIconTypes(brandId),
    [brandId]
  );

  const form = (
    <>
      <TextField
        {...register("title")}
        fullWidth
        margin="normal"
        label={"Title"}
        variant="outlined"
        autoComplete="off"
        error={!!errors.title}
        helperText={errors.title?.message}
        InputProps={{
          endAdornment: languageProps && (
            <LanguageSwitch
              {...languageProps}
              onSelectLanguage={(language) => {
                updateTranslations();
                languageProps.onSelectLanguage(language);
              }}
              size="small"
            />
          ),
        }}
        inputProps={{
          maxLength: 15,
        }}
      />
      {selectInputValue &&
      TABS_WITHOUT_URLS.includes(selectInputValue) ? null : (
        <TextField
          {...register("drawerUrl")}
          fullWidth
          margin="normal"
          label={"Url"}
          variant="outlined"
          autoComplete="off"
          error={!!errors.drawerUrl}
          helperText={errors.drawerUrl?.message}
        />
      )}
      <Grid
        container
        columns={8}
        overflow={"auto"}
        height={"400px"}
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "4px",
        }}
      >
        {Object.keys(FeatherIcons).map((iconName) => {
          const iconType = convertToIconType(iconName as IconName);
          return gridIcon({ key: iconName, iconType });
        })}
        {brandIconTypes.map((iconType) =>
          gridIcon({ key: iconType, iconType })
        )}
      </Grid>
    </>
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      (!isDirty &&
        icon === tab.icon &&
        selectInputValue === tab.type &&
        localizations === tab.localizations) ||
      icon == null ||
      selectInputValue === ""
    );
  }, [
    isDirty,
    icon,
    tab.icon,
    selectInputValue,
    tab.type,
    localizations,
    tab.localizations,
  ]);

  return (
    <RighthandDrawer
      isOpen={isDrawerOpen}
      title="Bottom Navigation Item"
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Stack
          sx={{
            margin: 2,
          }}
          spacing={2}
        >
          {!tab.type ? (
            <FormControl fullWidth>
              <InputLabel>Tab Type</InputLabel>
              <Select
                label="Tab Type"
                onChange={onSelectInputChange}
                value={selectInputValue}
              >
                {eligibleTabs.map((tab, index: number) => {
                  return (
                    <MenuItem key={index} value={tab}>
                      {getLabelMapping(tab)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="h6">{getLabelMapping(tab.type)}</Typography>
          )}
          {selectInputValue === "" ? null : form}
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitDisabled}
            >
              Done
            </Button>
          </Box>
        </Stack>
      </form>
    </RighthandDrawer>
  );
};

const TabsCard2 = ({ brand: brandKey }: Props) => {
  const snackbarContext = useContext(SnackbarContext);
  const dispatch = useMobilePreviewDispatch();

  const brand = useFragment<TabsCard2_brand$key>(brandFragment, brandKey);
  const buildNavigation = brand.appConfig.buildNavigation;

  const defaultTabs = brand.appConfig.tabs.map((tab) => {
    const { id, ...rest } = tab;
    return rest;
  });
  const [tabs, setTabs] = useState(defaultTabs);
  const eligibleTabs = buildNavigation.eligibleTabTypes.filter((tab) => {
    if (tab === "WEB") {
      return true;
    }
    return !tabs.some((t) => t.type === tab);
  });

  const [isTabBarTitleEnabled, setIsTabBarTitleEnabled] = useState(
    buildNavigation.isTabBarTitleEnabled
  );
  const onTabBarTitleEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsTabBarTitleEnabled(event.target.checked);
    dispatch({
      type: ReducerAction.UPDATE_NAVIGATION,
      payload: {
        navigation: {
          isTitleEnabled: event.target.checked,
        },
      },
    });
  };
  const [isTopTitleEnabled, setIsTopTitleEnabled] = useState(
    buildNavigation.isTopTitleEnabled
  );
  const onTopTitleEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsTopTitleEnabled(event.target.checked);
    dispatch({
      type: ReducerAction.UPDATE_NAVIGATION,
      payload: {
        navigation: {
          isTopTitleEnabled: event.target.checked,
        },
      },
    });
  };

  const isDirty =
    !deepEqual(tabs, defaultTabs) ||
    isTabBarTitleEnabled !== buildNavigation.isTabBarTitleEnabled ||
    isTopTitleEnabled !== buildNavigation.isTopTitleEnabled;

  const onUpdateTabIndex = (
    index: number,
    tab: {
      readonly customName: string;
      readonly customUrl: string | null;
      readonly icon: IconType;
      readonly type: TabType;
    }
  ) => {
    const newTabs = [...tabs];
    newTabs[index] = {
      ...newTabs[index],
      ...tab,
    };
    setTabs(newTabs);
  };
  const [saveButtonState, setSaveButtonState] = useState<SavedState>(
    SavedState.SAVED
  );

  const [drawerProps, setDrawerProps] = useState<DrawerTabProps | undefined>(
    undefined
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setDrawerProps(undefined);
  };
  const onDrawerOpen = ({
    index,
    tab,
  }: {
    index: number;
    tab: Partial<
      Pick<
        TabsCard2_brand$data["appConfig"]["tabs"][number],
        "type" | "customName" | "customUrl" | "localizations"
      >
    > &
      Pick<TabsCard2_brand$data["appConfig"]["tabs"][number], "icon">;
  }) => {
    setDrawerProps({ index, tab });
    setIsDrawerOpen(true);
  };

  const navigationLanguageProps = useLanguageSwitch({ brand });
  useEffect(() => {
    const localizedTabs = navigationLanguageProps?.selectedLanguage
      ? tabs.map((tab) => {
          const localized = tab.localizations.find(
            (name) =>
              name.languageCode === navigationLanguageProps.selectedLanguage
          );
          return {
            ...tab,
            customName: localized?.text ?? tab.customName,
            customUrl: localized?.url ?? tab.customUrl,
          };
        })
      : tabs;
    dispatch({
      type: ReducerAction.UPDATE_NAVIGATION,
      payload: {
        navigation: {
          tabs: localizedTabs,
        },
      },
    });
  }, [tabs, navigationLanguageProps?.selectedLanguage, dispatch]);

  const [saveMutation, isMutationInFlight] =
    useMutation<TabsCard2UpdateTabConfigMutation>(mutation);
  const right = (
    <Droppable droppableId={"tabs-droppable"} type={"PARENT"}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {tabs.map((tab, index) => {
            const Icon = getBrandIcon(tab.icon);
            const icon = <Icon size={24} color="#55B685" />;
            return (
              <Draggable
                key={index}
                draggableId={"draggable-" + index}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <DragIndicatorIcon />
                        </Box>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems="center"
                          alignContent={"center"}
                          width={"100%"}
                          sx={{
                            border: "1px solid #E0E0E0",
                            borderRadius: "8px",
                            height: "56px",
                            overflow: "hidden",
                            px: 2,
                            background: "#FDFDFE",
                          }}
                        >
                          {icon}
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Typography
                            flexGrow={1}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            {(navigationLanguageProps?.selectedLanguage &&
                              tab.localizations.find(
                                (name) =>
                                  name.languageCode ===
                                  navigationLanguageProps.selectedLanguage
                              )?.text) ??
                              tab.customName}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <IconButton
                            onClick={() => {
                              onDrawerOpen({ index, tab });
                            }}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                      {tabs.length > 3 && (
                        <IconButton
                          size={"small"}
                          onClick={() => {
                            const newTabs = [...tabs];
                            newTabs.splice(index, 1);
                            setTabs(newTabs);
                          }}
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      )}
                    </Stack>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <>
      <TabsCard2Drawer
        eligibleTabs={[...eligibleTabs]}
        onUpdateTabIndex={onUpdateTabIndex}
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        tab={drawerProps}
        brandDomain={brand.domain}
        brandId={brand.id}
        brand={brand}
        selectedLanguage={navigationLanguageProps?.selectedLanguage}
      />
      <CardSection
        actions={
          <SaveButton
            savedState={
              isMutationInFlight
                ? SavedState.DISABLED
                : isDirty
                ? SavedState.ENABLED
                : saveButtonState
            }
            onClick={() => {
              saveMutation({
                variables: {
                  input: {
                    brandId: brand.id,
                    isTabBarTitleEnabled,
                    isTopTitleEnabled,
                    tabInputs: tabs.map((tab, index) => ({
                      type: tab.type,
                      customName: tab.customName,
                      localizations: tab.localizations,
                      customUrl: tab.customUrl,
                      icon: tab.icon,
                      index,
                    })),
                  },
                },
                onCompleted: (data, errors) => {
                  if (errors) {
                    snackbarContext?.openSnackbar("Update Failed", "error");
                  } else {
                    snackbarContext?.openSnackbar(
                      "Update Successful",
                      "success"
                    );
                    setSaveButtonState(SavedState.SAVED);
                  }
                },
                onError: (error: Error) => {
                  snackbarContext?.openSnackbar("Update Failed", "error");
                },
              });
            }}
          />
        }
        title={"Navigation"}
        titleActions={
          navigationLanguageProps && (
            <LanguageSwitch {...navigationLanguageProps} size="small" />
          )
        }
        content={
          <>
            <Stack
              spacing={2}
              sx={{
                maxWidth: "650px",
              }}
            >
              <LeftRight
                expandLeft={true}
                left={
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Show Header Titles
                    </Typography>
                    <Typography variant="body2">
                      Enable or disable the titles at the top of the application
                    </Typography>
                  </Stack>
                }
                right={
                  <Switch
                    defaultChecked={isTopTitleEnabled}
                    onChange={onTopTitleEnabledChange}
                  />
                }
              />
              <LeftRight
                expandLeft={true}
                left={
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Show Bottom Icon Titles
                    </Typography>
                    <Typography variant="body2">
                      Enable or disable the titles under the navigation icons
                    </Typography>
                  </Stack>
                }
                right={
                  <Switch
                    defaultChecked={isTabBarTitleEnabled}
                    onChange={onTabBarTitleEnabledChange}
                  />
                }
              />
              <DragDropContext
                onDragEnd={(result) => {
                  const { destination, source } = result;

                  if (!destination) {
                    return;
                  }

                  if (
                    destination.droppableId !== source.droppableId &&
                    destination.index === source.index
                  ) {
                    return;
                  }

                  const newTabs = Array.from(tabs);
                  const [removed] = newTabs.splice(source.index, 1);
                  newTabs.splice(destination.index, 0, removed);
                  setTabs(newTabs);
                }}
              >
                {right}
              </DragDropContext>
              <Tooltip
                title={tabs.length >= 5 ? "Maximum 5 tabs" : ""}
                placement="right"
                arrow
              >
                <span>
                  <Button
                    disabled={tabs.length >= 5}
                    sx={{
                      flexShrink: 0,
                    }}
                    variant="contained"
                    color="secondaryLight"
                    onClick={() => {
                      onDrawerOpen({
                        index: tabs.length,
                        tab: {
                          icon: "HOME",
                        },
                      });
                    }}
                  >
                    <AddCircleOutlineIcon sx={{ mr: 1 }} />
                    Add new navigation
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </>
        }
      />
    </>
  );
};

export default TabsCard2;