import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

type Props = {
  title: string;
  titleActions?: React.ReactElement;
  subtitle?: string;
  content: React.ReactElement;
  actions?: React.ReactElement;
  showIsOptional?: boolean;
  variant?: "elevation" | "outlined";
  lockedContent?: string;
};

const CardSection = (props: Props) => {
  const {
    actions,
    title,
    titleActions,
    subtitle,
    content,
    variant = "elevation",
    showIsOptional = false,
    lockedContent,
  } = props;

  const isLocked = lockedContent !== undefined;
  let headerAction = null;

  let lockedItem = null;
  if (isLocked) {
    lockedItem = (
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 10,
        }}
      >
        <Tooltip title={lockedContent} arrow placement="right">
          <LockIcon />
        </Tooltip>
      </Box>
    );
  } else if (showIsOptional) {
    headerAction = (
      <Typography variant="overline" pr={2}>
        OPTIONAL
      </Typography>
    );
  }

  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "8px",
        marginBottom: "32px",
        border: "1px solid #DBDCDD",
        position: "relative",
      }}
      variant={variant}
    >
      <CardHeader
        sx={{
          borderBottom: "1px solid #DBDCDD",
          opacity: isLocked ? "40%" : undefined,
          pointerEvents: isLocked ? "none" : undefined,
        }}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={subtitle ? "8px" : 0}>
            <Typography variant="h6">{title}</Typography>
            {titleActions}
          </Stack>
        }
        subheader={
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        }
        action={headerAction}
      />
      <CardContent
        sx={{
          px: 3,
          py: 4,
          opacity: isLocked ? "40%" : undefined,
          pointerEvents: isLocked ? "none" : undefined,
        }}
      >
        <Box sx={{ minWidth: 0 }}>{content} </Box>
      </CardContent>
      {actions && (
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
            borderTop: "1px solid #DBDCDD",
            opacity: isLocked ? "40%" : undefined,
            pointerEvents: isLocked ? "none" : undefined,
          }}
        >
          {actions}
        </CardActions>
      )}
      {lockedItem}
    </Card>
  );
};

export default CardSection;
