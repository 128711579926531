/**
 * @generated SignedSource<<48b356cf901946ff485bbc4ce3aaee2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
export type UpdateMenuItemsInput = {
  appConfigId: string;
  menuItemsInput: ReadonlyArray<NestedMenuItemInputPartial>;
};
export type NestedMenuItemInputPartial = {
  children?: ReadonlyArray<NestedMenuItemInputPartial> | null;
  destinationUrl?: string | null;
  id?: string | null;
  index: number;
  localizations?: ReadonlyArray<LocalizedUrlInput> | null;
  title: string;
};
export type LocalizedUrlInput = {
  languageCode: KinnLanguageCode;
  text: string;
  url?: string | null;
};
export type MenuCardMutation$variables = {
  input: UpdateMenuItemsInput;
};
export type MenuCardMutation$data = {
  readonly updateMenuItems: {
    readonly " $fragmentSpreads": FragmentRefs<"MenuCard_brand">;
  };
};
export type MenuCardMutation = {
  response: MenuCardMutation$data;
  variables: MenuCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LocalizedUrlType",
  "kind": "LinkedField",
  "name": "localizations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languageCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MenuCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateMenuItems",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MenuCard_brand"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MenuCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BrandType",
        "kind": "LinkedField",
        "name": "updateMenuItems",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandAppConfigType",
            "kind": "LinkedField",
            "name": "appConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportedLanguages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableLanguageSpecificContent",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NestedMenuItemType",
                "kind": "LinkedField",
                "name": "nestedMenuItems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NestedMenuItemType",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NestedMenuItemType",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a3261dd8324aef062b54d308f264913",
    "id": null,
    "metadata": {},
    "name": "MenuCardMutation",
    "operationKind": "mutation",
    "text": "mutation MenuCardMutation(\n  $input: UpdateMenuItemsInput!\n) {\n  updateMenuItems(input: $input) {\n    ...MenuCard_brand\n    id\n  }\n}\n\nfragment MenuCard_brand on BrandType {\n  id\n  ...useBrandLanguages_brand\n  appConfig {\n    id\n    nestedMenuItems {\n      title\n      localizations {\n        languageCode\n        text\n        url\n      }\n      destinationUrl\n      index\n      children {\n        title\n        destinationUrl\n        index\n        localizations {\n          languageCode\n          text\n          url\n        }\n        children {\n          title\n          destinationUrl\n          index\n          localizations {\n            languageCode\n            text\n            url\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment useBrandLanguages_brand on BrandType {\n  appConfig {\n    supportedLanguages\n    enableLanguageSpecificContent\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "43dadc7ff853649e545c9f97a811c2e3";

export default node;
