import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Box, Button, CardMedia, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useMobilePreviewState } from "../../contexts/MobilePreviewContext";


const TimeLeftSection = ({ time, unit }: { time: string; unit: string }) => {
  return (
    <Stack direction="column" alignItems="center">
      <Typography variant="h6">{time}</Typography>
      <Typography variant="caption">{unit}</Typography>
    </Stack>
  );
};

const DailyDealPreview = () => {
  const theme = useTheme();
  const { dailyDeal } = useMobilePreviewState();
  const [isActive, setIsActive] = useState(false);

  const imageCard = (
    <CardMedia
      sx={{
        objectFit: "contain",
        filter: isActive ? "none" : "blur(10px)",
        maxHeight: "360px",
        ...(isActive
          ? {
              aspectRatio: "1 / 1",
              maskImage:
                "radial-gradient(ellipse farthest-corner at 45px 45px, rgba(50, 50, 50, 1) 0%, rgba(80, 80, 80, 0.7) )",
            }
          : {}),
      }}
      component="img"
      image={dailyDeal.imageUrl ?? undefined}
    />
  );

  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "3.75px solid #DBDCDD",
        justifyContent: "center",
        overflow: "hidden",
        height: "650px",
        alignItems: "center",
        backgroundColor: theme.palette.onboardingBackground.main,
        color: theme.palette.onboardingForeground.main,
        mt: 2,
        position: "relative",
      }}
      id="drawer-container"
    >
      {/* nav bar */}
      <Stack
        sx={{
          height: "54px",
          justifyContent: "center",
          color: theme.palette.navigationForeground.main,
          backgroundColor: theme.palette.navigationBackground.main,
        }}
        alignItems="center"
      >
        <Button
          sx={{
            borderRadius: 2,
            color: theme.palette.navigationBackground.main,
            background: theme.palette.navigationForeground.main,
            "&:hover": {
              background: theme.palette.navigationForeground.main,
            },
            fontSize: "0.75rem",
          }}
        >
          <MonetizationOnOutlinedIcon sx={{ mr: 1 }} />
          {dailyDeal.title || "Daily Deal"}
        </Button>
        <IconButton
          sx={{
            position: "absolute",
            top: 6,
            right: 0,
            color: theme.palette.navigationForeground.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      {/* Content */}
      <Stack spacing={2} p={2} height="calc(100% - 52px)">
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            maxHeight: "60px",
            alignContent: "center",
          }}
        >
          {(isActive ? dailyDeal.headline : dailyDeal.blurredHeadline).replace(
            "$time_left",
            "15 min"
          ) || "Headline"}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            maxHeight: "40px",
            alignContent: "center",
          }}
        >
          {(isActive
            ? dailyDeal.subheadline
            : dailyDeal.blurredSubheadline
          ).replace("$time_left", "15 min") || "Subheadline"}
        </Typography>

        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
          direction="column"
        >
          {!isActive ? (
            imageCard
          ) : (
            <>
              <Box m={2} sx={{ width: "-webkit-fill-available" }}>
                <Box
                  position="absolute"
                  width="80%"
                  borderRadius="50%"
                  sx={{
                    borderWidth: "15px",
                    borderStyle: "solid",
                    borderColor: theme.palette.onboardingBackground.main,
                    filter: "contrast(0.5)",
                    aspectRatio: "1 / 1",
                  }}
                ></Box>
                <Box
                  position="absolute"
                  width="80%"
                  borderRadius="50%"
                  sx={{
                    borderWidth: "15px",
                    borderStyle: "solid",
                    borderColor: theme.palette.onboardingForeground.main,
                    aspectRatio: "1 / 1",
                    mask: "linear-gradient(red, red) padding-box, conic-gradient(red 80%, transparent 0%) border-box",
                    transform: "scale(-1, 1)",
                  }}
                ></Box>
                <Box position="relative" m={3}>
                  {imageCard}
                </Box>
              </Box>
              <Stack direction="row" spacing={2} alignContent="center">
                <TimeLeftSection time="00" unit="HOURS" />
                <TimeLeftSection time="14" unit="MINUTES" />
                <TimeLeftSection time="38" unit="SECONDS" />
              </Stack>
            </>
          )}
        </Stack>

        <Box position="relative" width="100%">
          {!isActive && (
            <Box
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                borderRadius: 2,
                background: theme.palette.primaryButtonFg.main,
                filter: "contrast(0.5)",
              }}
            />
          )}
          <Button
            variant="contained"
            sx={{
              borderRadius: 2,
              m: 0,
              p: 0,
              overflow: "hidden",
              height: "48px",
              width: "100%",

              ...(isActive
                ? {
                    color: theme.palette.primaryButtonFg.main,
                    background: theme.palette.primaryButtonBg.main,
                    "&:hover": {
                      background: theme.palette.primaryButtonBg.main,
                    },
                  }
                : {
                    color: theme.palette.primaryButtonBg.main,
                    background: "transparent",
                    "&:hover": {
                      background: "transparent",
                    },
                  }),
            }}
            onClick={() => setIsActive(!isActive)}
          >
            {!isActive && (
              <Button
                variant="contained"
                sx={{
                  position: "relative",
                  height: "100%",
                  boxShadow: 1,
                  borderRadius: 2,
                  color: theme.palette.primaryButtonFg.main,
                  background: theme.palette.primaryButtonBg.main,
                  "&:hover": {
                    background: theme.palette.primaryButtonBg.main,
                  },
                }}
              >
                <KeyboardArrowRightIcon />
              </Button>
            )}
            <Typography
              variant="button"
              sx={{
                padding: 1,
                width: "-webkit-fill-available",
              }}
            >
              {isActive ? "Get this deal" : "Swipe to reveal"}
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default DailyDealPreview;