import { graphql } from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { useBrandLanguages_brand$key } from "./__generated__/useBrandLanguages_brand.graphql";
import {
  KinnLanguage,
  KinnLanguageCode,
  LANGUAGE_MAP,
} from "../utils/languageMap";

const brandFragment = graphql`
  fragment useBrandLanguages_brand on BrandType {
    appConfig {
      supportedLanguages
      enableLanguageSpecificContent
    }
  }
`;

export interface BrandLanguages {
  defaultLanguage: KinnLanguage;
  supportedLanguages: KinnLanguage[];
}

const useBrandLanguages = ({
  brand: brandKey,
}: {
  brand: useBrandLanguages_brand$key;
}): BrandLanguages | null => {
  const brand = useFragment(brandFragment, brandKey);

  const brandLanguages = useMemo(() => {
    return brand.appConfig.supportedLanguages?.map((lang: string) => {
      const langCode = lang
        .split(/-|_/)[0] // split by dash (-) or underscore (_) for cases like en-US or en_US
        .toUpperCase() as KinnLanguageCode;
      return (
        LANGUAGE_MAP[langCode] ?? {
          code: langCode,
          label: lang,
        }
      );
    });
  }, [brand.appConfig.supportedLanguages]);

  if (
    !brand.appConfig.enableLanguageSpecificContent ||
    !brandLanguages ||
    brandLanguages.length <= 1
  ) {
    return null;
  }

  return {
    defaultLanguage:
      brandLanguages.find((lang) => lang.code === "EN") ?? brandLanguages[0],
    supportedLanguages: brandLanguages,
  };
};

export default useBrandLanguages;
