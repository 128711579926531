/**
 * @generated SignedSource<<0bf08d438f747b30aa55e0f4d98c9fdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBrandLanguages_brand$data = {
  readonly appConfig: {
    readonly enableLanguageSpecificContent: boolean;
    readonly supportedLanguages: ReadonlyArray<string> | null;
  };
  readonly " $fragmentType": "useBrandLanguages_brand";
};
export type useBrandLanguages_brand$key = {
  readonly " $data"?: useBrandLanguages_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBrandLanguages_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useBrandLanguages_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportedLanguages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enableLanguageSpecificContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "c1a976720a028b7c0e3aaa94abd25694";

export default node;
