/**
 * @generated SignedSource<<3fd70584cdc1d633e1af2e614a071696>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CDPAudienceLanguageSelector_brand$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useBrandLanguages_brand">;
  readonly " $fragmentType": "CDPAudienceLanguageSelector_brand";
};
export type CDPAudienceLanguageSelector_brand$key = {
  readonly " $data"?: CDPAudienceLanguageSelector_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"CDPAudienceLanguageSelector_brand">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CDPAudienceLanguageSelector_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useBrandLanguages_brand"
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};

(node as any).hash = "3d0c7b3ed2f2b03b3c613260c339596d";

export default node;
