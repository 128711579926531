/**
 * @generated SignedSource<<4c413531e721b950ab872acf8e0dbf0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShortcutsCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly navigationItems: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly index: number;
          readonly label: string;
          readonly localizations: ReadonlyArray<{
            readonly languageCode: KinnLanguageCode;
            readonly text: string;
            readonly url: string | null;
          }>;
          readonly url: string;
        }>;
        readonly index: number;
        readonly label: string;
        readonly localizations: ReadonlyArray<{
          readonly languageCode: KinnLanguageCode;
          readonly text: string;
          readonly url: string | null;
        }>;
        readonly url: string;
      }>;
      readonly index: number;
      readonly label: string;
      readonly localizations: ReadonlyArray<{
        readonly languageCode: KinnLanguageCode;
        readonly text: string;
        readonly url: string | null;
      }>;
      readonly url: string;
    }>;
  };
  readonly id: string;
  readonly shopifyConfig: {
    readonly isConnected: boolean;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useBrandLanguages_brand">;
  readonly " $fragmentType": "ShortcutsCard_brand";
};
export type ShortcutsCard_brand$key = {
  readonly " $data"?: ShortcutsCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShortcutsCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LocalizedUrlType",
  "kind": "LinkedField",
  "name": "localizations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languageCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShortcutsCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useBrandLanguages_brand"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationItemType",
          "kind": "LinkedField",
          "name": "navigationItems",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NavigationItemType",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NavigationItemType",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandShopifyConfigType",
      "kind": "LinkedField",
      "name": "shopifyConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isConnected",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "b16a40c8c65e148a7d6cf1dbaef24310";

export default node;
