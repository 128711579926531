/**
 * @generated SignedSource<<7ceb0a26f52ea33b9c08bfd45960743e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KinnLanguageCode = "EN" | "ES" | "FR" | "PT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MenuCard_brand$data = {
  readonly appConfig: {
    readonly id: string;
    readonly nestedMenuItems: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly destinationUrl: string | null;
          readonly index: number;
          readonly localizations: ReadonlyArray<{
            readonly languageCode: KinnLanguageCode;
            readonly text: string;
            readonly url: string | null;
          }>;
          readonly title: string;
        }>;
        readonly destinationUrl: string | null;
        readonly index: number;
        readonly localizations: ReadonlyArray<{
          readonly languageCode: KinnLanguageCode;
          readonly text: string;
          readonly url: string | null;
        }>;
        readonly title: string;
      }>;
      readonly destinationUrl: string | null;
      readonly index: number;
      readonly localizations: ReadonlyArray<{
        readonly languageCode: KinnLanguageCode;
        readonly text: string;
        readonly url: string | null;
      }>;
      readonly title: string;
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useBrandLanguages_brand">;
  readonly " $fragmentType": "MenuCard_brand";
};
export type MenuCard_brand$key = {
  readonly " $data"?: MenuCard_brand$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuCard_brand">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LocalizedUrlType",
  "kind": "LinkedField",
  "name": "localizations",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languageCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuCard_brand",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useBrandLanguages_brand"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandAppConfigType",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "NestedMenuItemType",
          "kind": "LinkedField",
          "name": "nestedMenuItems",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NestedMenuItemType",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NestedMenuItemType",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandType",
  "abstractKey": null
};
})();

(node as any).hash = "fad6f732726c207263977faf4edf662d";

export default node;
