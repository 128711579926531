import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useBrandLanguages_brand$key } from "../hooks/__generated__/useBrandLanguages_brand.graphql";
import useBrandLanguages, { BrandLanguages } from "../hooks/useBrandLanguages";
import { KinnLanguageCode } from "../utils/languageMap";


interface LanguageSwitchProps {
  supportedLanguages: BrandLanguages["supportedLanguages"];
  supportedLanguageCodes: KinnLanguageCode[];
  selectedLanguage: KinnLanguageCode;
  onSelectLanguage: (language: KinnLanguageCode) => void;
}

export const useLanguageSwitch = ({
  brand,
  initialLanguage,
}: {
  brand: useBrandLanguages_brand$key;
  initialLanguage?: KinnLanguageCode;
}): LanguageSwitchProps | undefined => {
  const brandLanguages = useBrandLanguages({ brand });
  const [selectedLanguage, setSelectedLanguage] = useState<
    KinnLanguageCode | undefined
  >(initialLanguage ?? brandLanguages?.defaultLanguage?.code);

  useEffect(() => {
    if (!initialLanguage) {
      return;
    }
    setSelectedLanguage(initialLanguage);
  }, [initialLanguage]);

  const supportedLanguageCodes = useMemo(
    () => brandLanguages?.supportedLanguages.map((lang) => lang.code),
    [brandLanguages]
  );

  if (!brandLanguages || !selectedLanguage || !supportedLanguageCodes) {
    return;
  }

  return {
    supportedLanguages: brandLanguages.supportedLanguages,
    supportedLanguageCodes,
    selectedLanguage,
    onSelectLanguage: setSelectedLanguage,
  };
};

const LanguageSwitch: React.FC<
  LanguageSwitchProps &
    Omit<
      React.ComponentProps<typeof ToggleButtonGroup>,
      "value" | "onChange" | "ref"
    >
> = ({
  supportedLanguages,
  supportedLanguageCodes,
  selectedLanguage,
  onSelectLanguage,
  ...props
}) => {
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedLanguage}
      exclusive
      onChange={(event, newLanguage: KinnLanguageCode) => {
        if (newLanguage) {
          onSelectLanguage(newLanguage);
        }
      }}
      aria-label="Platform"
      {...props}
    >
      {supportedLanguages.map((language) => (
        <ToggleButton key={language.code} value={language.code}>
          {language.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default LanguageSwitch;