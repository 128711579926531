import { KinnLanguageCode as GraphQLLanguageCode } from "../views/dashboard/engage/notifications/__generated__/NotificationFormCreateMutation.graphql";


export type KinnLanguageCode = GraphQLLanguageCode;

export type KinnLanguage = {
  code: KinnLanguageCode;
  label: string;
  labelLong?: string;
};

export const LANGUAGE_MAP: { [key in KinnLanguageCode]: KinnLanguage | null } =
  {
    EN: {
      code: "EN",
      label: "English",
      labelLong: "English (Default)",
    },
    ES: { code: "ES", label: "Spanish" },
    FR: { code: "FR", label: "French" },
    PT: { code: "PT", label: "Portuguese" },
    "%future added value": null,
  };